import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'

import { routeMap } from '/routeMap'
import { useTranslate } from '/machinery/I18n'

import { Icon } from '/features/buildingBlocks/Icon'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { RewardCalculatorPortalFlow } from '/features/buildingBlocks/reward-calculator/RewardCalculatorPortalFlow'

import styles from './RewardCalculatorCta.css'

import iconMoney from '/images/icons/stapel-muntjes.raw.svg'

export function RewardCalculatorCta({ title, link }) {
  const { __ } = useTranslate()

  const [isOpen, setIsOpen] = React.useState(false)

  const benefitsPageRef = link?.ref && {
    url: determineDocumentPathSync({ document: link.ref, routeMap }),
    label: link.label
  }

  return (
    <>
      <div className={styles.cta}>
        <div className={styles.inner}>
          <Icon icon={iconMoney} />

          <div className={styles.content}>
            {title && <HeadingMd layoutClassName={styles.headingLayout} h={3} {...{ title }} />}

            <ButtonPrimary
              dataX='open-reward-calculator'
              label={__`calculate-your-salary`}
              onClick={() => setIsOpen(true)}
            />
          </div>
        </div>
      </div>

      <RewardCalculatorPortalFlow
        onChange={handleOpenChange}
        link={benefitsPageRef}
        {...{ isOpen }}
      />
    </>
  )

  function handleOpenChange() {
    setIsOpen(!isOpen)
  }
}
